<template>
    <h1>Fill out the form to share your information</h1>
<v-container >
    <form @submit.prevent="handleSubmit">
        <v-text-field v-model="name" class="ma-4" variant="solo" label="Name" id="name" />
        <v-text-field v-model="surname" class="ma-4" variant="solo"  label="Surname" id="surname" />
        <v-text-field v-model="jobtitle" class="ma-4" variant="solo"  label="Job title" id="jobtitle"/>
        <v-text-field v-model="graduationyear" class="ma-4" variant="solo"  label="Graduation Year" id="graduationyear" /> 
        <v-btn  color="purple-darken-2" type="submit" class="submitbutton">Submit</v-btn>
    </form>
</v-container>


</template>
<script>
import { ref } from "vue";
import axios from "axios";

export default {
    setup() {
        const name = ref("");
        const surname = ref("");
        const jobtitle = ref("");
        const graduationyear = ref("");

        const handleSubmit = async () => {
            try {
               const response = await axios.post('http://localhost:5000/add-user',{
                name: name.value,
                surname: surname.value,
                jobtitle:jobtitle.value,
                graduationyear: graduationyear.value,
               });
               console.log(response.data);
               name.value="";
               surname.value="";
               jobtitle.value="";
               graduationyear.value="";
            } catch(error){
                console.error('Bir hata olustu', error)
                
            }
        };
        return {// template de erişebilelim diye
            name,
            surname,
            jobtitle,
            graduationyear,
            handleSubmit,

        };
    },
};
</script>
<style>


</style>
