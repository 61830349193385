<template>
    <div>
      <h1>Chart komponenti</h1>
      <img :src="chartUrl" alt="Meslek Dağılımı Grafiği" />
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  
  const chartUrl =ref('http://localhost:5001/update_chart');// Flask sunucusunun portunu kullanın
  let intervalId = null;
  
const updateChart = () => {
  chartUrl.value = `http://localhost:5001/update_chart?timestamp=${new Date().getTime()}`;
};

  const checkForUpdates = async () => {
    try {
      const response = await fetch('http://localhost:5000/check-update');
      const data = await response.json();
      if (data.updated) {
        updateChart(); // Grafiği güncelle
      }
    } catch (error) {
      console.log('Güncelleme kontrolünde bir hata oluştu', error);
    }
  };
  
  onMounted(() => {
    updateChart(); // İlk yüklemede grafiği güncelle
    checkForUpdates(); // İlk kontrol
    intervalId = setInterval(checkForUpdates, 10000); // Her 10 saniyede bir güncelleme kontrolü
  });
  
  onBeforeUnmount(() => {
    clearInterval(intervalId); // Sayfa kapanmadan intervali temizle
  });
  </script>
  